<template>
  <v-autocomplete
    v-model="client"
    v-bind="$attrs"
    :items="$store.state.selects.clients"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('client', multiple ? 2 : 1) | capitalize"
    cache-items
    dense
    :multiple="multiple"
    :small-chips="multiple"
    :clearable="!$attrs.readonly"
    menu-props="offsetY"
    item-text="name"
    item-value="uuid"
    @click:clear="uuid = null; fetchClients"
    @change="$emit('update:value', client)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-building
      </v-icon>
    </template>
    <template
      v-if="multiple"
      #selection="{ item, index }"
    >
      <v-avatar
        v-if="index < 3"
        size="20"
        class="mr-1"
        tile
        outlined
      >
        <v-img :src="item.icon" />
      </v-avatar>
      <span
        v-if="index === 3"
        class="grey--text text-caption"
      >
        (+{{ client.length - 3 }} more)
      </span>
    </template>
    <template #item="{ item }">
      <v-avatar
        size="22"
        tile
        class="mr-2"
      >
        <v-img :src="item.icon" />
      </v-avatar>
      <small>{{ item.name }}</small>
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Object, String],
        default: () => [],
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        uuid: null,
        client: this.value,
      }
    },
    watch: {
      search () {
        this.fetchClients()
      },
      value () {
        this.client = this.value
      },
    },
    created () {
      if (this.value.length && typeof this.value === 'string' && !this.$attrs.readonly) {
        this.uuid = this.value
      }
      if (this.$store.state.selects.clients.length === 0 || !this.$store.state.selects.clients.map(x => x.uuid).includes(this.value)) {
        this.fetchClients()
      }
    },
    methods: {
      fetchClients () {
        this.loading = true
        const promise = this.axios.get('admin/clients', {
          params: {
            ...this.params,
            ...{
              fields: ['uuid','name'],
              search: this.search,
              uuid: this.uuid,
              sort: 'name',
            },
          },
        })
        return promise.then((response) => {
          this.$store.state.selects.clients = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
